<template>
  <div class="w-100 d-flex flex-column">
    <base-tool-bar title="Retour d'expérience" subtitle="Informations générales"></base-tool-bar>

    <base-form-group
      title="Fiches REX"
      dense
      icon="book"
      v-background-3
      class="shadow-sm rounded mb-3"
    >
      <span class="text-muted">
        <small>Archivez ici vos documents utiles au REX</small>
      </span>
      <div class="w-100">
        <uploader
          v-if="id"
          id="document_affaire_rex"
          url="/upload/document"
          title="Retour d'expérience"
          :file_system="'CRM'"
          :file_field="'rex'"
          :file_key="id"
        ></uploader>
      </div>
    </base-form-group>

    <base-form class="mr-md-3" @click="updateAffaire" :loading="loading" shadow>
      <base-form-group title="Synthèse" dense icon="check-square">
        <span class="text-muted">
          <small>Veuillez indiquer ici les informations reccueillies</small>
        </span>
        <base-form-row>
          <base-input-area
            v-model="affaire_rex_synthese"
            text="Synthèse du retour d'expérience"
            :errors="feedback.affaire_rex_synthese"
          ></base-input-area>
        </base-form-row>
        <base-form-row row>
          <vue-tags-input
            placeholder="+ raccourcis"
            class="w-100 rounded"
            v-model="tag"
            :tags="tagsData"
            @tags-changed="newTags => tagChanged(newTags)"
          />
        </base-form-row>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import Uploader from "@/components/bases/Uploader.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
//import BaseInput from "@/components/bases/Input.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  components: {
    BaseToolBar,
    BaseForm,
    BaseFormGroup,
    Uploader,
    BaseFormRow,
    //BaseInput,
    BaseInputArea,
    VueTagsInput
  },
  data() {
    return {
      loading: false,
      lists: {},
      feedback: {},
      tag: ""
    };
  },
  computed: {
    ...mapFields("affaires", [
      "affaire.id",
      "affaire.affaire_rex_synthese",
      "affaire.affaire_rex_tags"
    ]),
    tagsData() {
      let d = this.affaire_rex_tags;
      let t = [];
      let r = [];

      if (d == undefined || d.length < 1) return [];
      t = d.split(";");

      t.forEach(e => {
        if (e)
          r.push({
            text: e
          });
      });
      return r;
    }
  },
  methods: {
    ...mapActions({
      update: "affaires/updateAffaire"
    }),
    updateAffaire: function() {
      this.loading = true;
      this.feedback = {};
      this.update()
        .catch(error => this.errHandler(error))
        .finally(() => (this.loading = false));
    },
    errHandler: function(error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
    tagChanged: function(tags) {
      this.affaire_rex_tags = tags.map(e => e.text).join(";");
      this.updateAffaire();
    }
  }
};
</script>
<style>
.vue-tags-input {
  background: none !important;
  background-color: none !important;
  max-width: none !important;
}

.ti-input {
  border: none !important;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
}

.vue-tags-input .ti-input {
  transition: border-bottom 200ms ease;
}

.ti-tag {
  background-color: #007bff !important;
}

.ti-item {
  padding: 3px;
}

.ti-autocomplete {
  background-color: black !important;
  color: white;
  border-radius: 0.2rem;
  z-index: 2100 !important;
}

.ti-item.ti-valid.ti-selected-item {
  background-color: black !important;
  color: #007bff;
  border-radius: 0.2rem;
}
</style>
